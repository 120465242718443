import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_multicast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_multicast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_multicast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_multicast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMulticast(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_multicast/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_multicast', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMulticast(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/send_multicast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
